<template>
  <div class="privacy">
    <h3 class="title">口袋时间隐私政策</h3>
    <p class="right">更新于 2021 年 12 月 28 日</p>
    <p class="content">
      为切实保护用户隐私权益、优化用户体验，<em>厦门逆为网络科技有限公司（含其关联公司，以下称“逆为”或“我们”）</em>根据现行法规及政策，制定本隐私政策。本隐私政策将详细说明我们在获取、管理及保护用户个人信息方面的政策及措施。本隐私政策适用于我们为您提供的所有服务，无论您是通过移动终端或所有设备获得的服务。
    </p>
    <p class="content">
      以下政策是厦门逆为网络科技有限公司对用户隐私保护的许诺，请您务必仔细阅读，以了解我们关于管理您个人信息的情况。本隐私政策属于"厦门逆为网络科技有限公司用户用户协议"的重要组成部分，与其具有同等法律效力。
    </p>
    <p class="content">
      本隐私政策所适用的产品和服务由厦门逆为网络科技有限公司及其关联公司运营，注册地址为厦门火炬高新区创业园轩业楼3473室，联系邮件为：service@nilwy.com。对于本隐私政策或相关事宜有任何问题，您可随时通过该邮件与我们取得联系。
    </p>
    <p>本隐私政策部分将帮助您了解以下内容：</p>
    <p class="bold">1）我们如何收集和使用您的个人信息；</p>
    <p class="bold">2）我们如何使用 Cookie 和同类技术；</p>
    <p class="bold">3）个人信息的保护和安全措施；</p>
    <p class="bold">4）我们如何共享、转让、公开披露您的个人信息；</p>
    <p class="bold">5）数据处理的法律基础；</p>
    <p class="bold">6）您如何管理您的个人信息；</p>
    <p class="bold">7）对未成年人个人信息的特别保护。</p>
    <p class="bold">8）第三方sdk目录；</p>
    <p class="bold">1. 我们如何收集和使用您的个人信息</p>
    <p class="content">
      本隐私政策是您使用我们服务及各项功能的基础性文件，我们希望您在使用我们服务前仔细阅读并明确您已经充分理解、接受本政策的内容，希望您可以根据自己的理解做出合适的选择。我们努力通过通俗的语言表述本隐私政策中涉及的相关术语，并提供进一步说明的链接，以便您更好地理解。在我们更新本隐私政策后（我们会及时提示您更新的情况）您继续使用我们的产品与/或服务，即意味着您同意本隐私政策(含更新版本）内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。
    </p>
    <p class="content">
      我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括：
    </p>
    <p class="bold">（1）注册/登录所必要的信息</p>
    <p>
      如果您需使用评论等需要注册/登录后才能使用的功能，则需要您根据自行选择的注册/登录方式，向我们提供以下个人信息：
    </p>
    <p>
      手机号码
      ，是您选择使用手机号码注册/登录时，主动向我们提供的个人信息。同时，我们将通过发送短信验证码的方式来验证您的身份是否有效。
    </p>

    <p>
      如果您不提供这些信息，则您无法注册/登录，且无法使用需注册/登录的功能，但不会影响您正常使用无需注册/登录的功能。
    </p>
    <p class="bold">（2）自主选择完善的信息</p>
    <p>
      您登录之后可以完善您的个人资料，您一旦选择、编辑、填写了您的信息，就视为您同意提供，请您谨慎提供。您可以完善的个人资料包括更换头像、更改用户名，选择性别、生日。
    </p>
    <p>
      您更换头像时，需要调用您的相册权限（iOS系统），如果您拒绝调用，则无法更换头像。头像、昵称是用于公开展示您自己的形象。
    </p>
    <p class="bold">（3）我们从第三方合作伙伴处获取的信息</p>
    <p>
      如果您选择使用第三方账号（微信）注册/登录时，我们会根据您的授权，以及我们与第三方的合作协议，获得您的第三方应用账号（微信）和昵称、头像等公开信息（请以授权登录界面显示的信息为准）。
    </p>
    <p>
      如果您不同意我们获取上述信息的，将无法使用第三方账号登录/注册我们的产品/服务，但可以选择其他方式（手机号码）登录/注册。
    </p>
    <p>
      个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策中涉及的个人敏感信息包括：
    </p>
    <p class="bold">
      1）网络身份识别信息：包括账户名、账户昵称、邮箱地址及与前述有关的密码与密码保护问题和答案；
    </p>
    <p class="bold">
      2）其他信息：包括手机号码、软件使用记录、网页浏览记录、精准定位信息；
    </p>
    <p class="bold">3）相关身份证明。</p>
    <p class="content">
      您已知悉且同意，在您注册我们帐号或使用我们提供的服务时，我们将记录您提供的相关个人信息，如：密码、手机号码等，上述个人信息是您获得我们提供服务的基础。
    </p>
    <p class="content">
      同时，基于优化用户体验之目的，我们会获取与提升我们服务有关的其他信息，例如当您访问我们时，我们可能会收集哪些服务的受欢迎程度、浏览器软件信息等数据来不断改进我们的服务。
    </p>
    <p class="bold">我们仅会在出现下列情况时收集您的个人信息：</p>
    <p class="content">
      在您注册我们帐号时，我们至少会收集您的账户昵称、性别、手机号码等信息。若您以其他方式关联登录我们，我们会向关联第三方请求您的个人信息，对于我们要求但第三方无法提供的个人信息，我们仍可以要求您提供。如果您拒绝提供这些信息，将影响您使用我们或我们的相关功能。在您注册使用我们时，我们会对您进行实名验证，如果您拒绝提供有关信息，我们将拒绝向您提供服务。
    </p>
    <p class="content">
      在您发送我们、使用我们提供的位置定位服务时，我们会收集您的位置信息、设备信息等。我们保证在您的设备使用定位服务时不会收集其他无关信息。
    </p>
    <p class="content">
      在您使用我们提供的搜索服务时，我们会收集您的查询关键字信息、设备信息等，为了提供高效的搜索服务，这些信息有部分会暂时存储在您的本地存储设备之中。在此，您需要注意的是，您的关键词信息无法单独识别您的个人身份，其不属于您的个人信息，因此我们有权以其他的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息互有联系并可以识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。
    </p>
    <p class="content">
      当您参加我们的有关营销活动时，我们可能会收集您的姓名、通讯地址、联系方式、银行帐号等信息。这些信息是您收到转账或礼品的基础，如果您拒绝提供这些信息，我们将无法向您转账或发放礼品。
    </p>
    <p class="content">
      我们会收集您设备的具体位置信息为您提供您所在城市的有关资讯，我们所收集的信息中不会涉及您设备的其他信息。
    </p>
    <p class="content">
      当您在我们应用中使用“专注”模块的沉浸模式功能时，为了检测您在专注过程中是否打开除了口袋时间以外的其他产品，我们将收集您设备上已安装的应用列表。
    </p>
    <p class="content">
      当您在我们中使用第三方提供的服务时
      ，我们视为您允许第三方收集您的订单信息、支付信息等，如果您拒绝第三方在提供服务时收集此类信息，将可能会导致您无法在我们中使用第三方服务。
    </p>
    <p class="content">
      当您通过购买订阅服务或商品产生付费行为时，我们会通过第三方应用（包括但不限于应用商店、社交网络以及其他第三方支付平台）处理您的订单。第三方应用可能以口袋时间名义收集您的某些财务信息，如您的姓名、电子邮箱地址和其它订单信息。
    </p>
    <p class="content">
      基于对您信息的有效保护并且在您同意的基础上，我们会对收集到的您的信息进行去标签化处理，以保护您的信息安全。在此希望您了解并接受在不透露您个人信息的前提下，我们有权对您的信息进行分析并予以商业化的利用。
    </p>
    <p class="content">
      当您通过我们登录第三方应用时，第三方可能会通过我们获得您的个人昵称、头像等信息。如果您拒绝提供此类功能，您可以在我们相关设置中关闭。当涉及国家安全与利益、社会公共利益、与犯罪侦查有关的相关活动、您或他人生命财产安全但在特殊情况下无法获得您的及时授权、能够从其他合法公开的渠道、法律法规规定的其他情形下，我们可能在不经过您的同意或授权的前提下，收集您的个人信息。
      在某些特殊情况下，我们会要求您提供相关证明等纸质版信息。在我们收到您的纸质版信息的
      30 天内，我们会妥善处理您的信息并及时告知您。
      如果你选择申请验证医疗人员身份，我们将会收集你的身份证明信息以及你的邮箱，以便完成身份验证。
    </p>
    <p class="content">
      另，如在上述场景之外获得您的个人信息，将重新征得您的明示同意，并在获得您明示同意前向您充分说明应用场景与获取您相关信息的内容与范围。
    </p>
    <p class="bold">2、 我们如何使用 Cookie 和同类技术</p>
    <p class="content">
      Cookie
      可以帮助网站辨认注册用户，计算用户数量，通常被各网站用来判定完成注册的用户是否已经实现登录。我们承诺，对
      Cookie
      信息的研究仅用于提升服务/产品质量及优化用户体验之目的。同时，如不希望个人信息保留在
      Cookie 中，您可以对浏览器进行配置：选择“拒绝 Cookie”或“当网站发送 Cookie
      时通知您”，您知道，鉴于我们的服务是通过支持 Cookie 来是实现的，完成关闭 Cookie
      的操作后，可能影响到您访问我们或不能充分取得我们的服务。您不仅用 Cookie
      时，可能会得到提示，是否在下一次进入此网站时保留用户信息以便简化登录手续（如一键登录）。
    </p>
    <p class="content">我们使用 Web Beacon 的方法和目的与 Cookie 是相同的。</p>
    <p class="content">
      为了向您提供更好的服务或产品，我们会在下述情形使用您的个人信息：
    </p>
    <p class="bold">1）根据相关法律法规的要求；</p>
    <p class="bold">2）根据您的授权；</p>
    <p class="bold">3）个人信息的保护和安全措施；</p>
    <p class="bold">4）根据我们相关用户协议、应用许可使用协议的约定。</p>
    <p class="bold">3、个人信息的保护和安全措施</p>
    <p class="content">
      我们将尽一切合理努力保护其获得的用户个人信息，并由专门的数据安全部门对个人信息进行保护。为防止用户个人信息在意外的、未经授权的情况下被非法访问、复制、修改、传送、遗失、破坏、处理或使用，我们已经并将继续采取以下措施保护您的个人信息：
    </p>
    <p class="bold">3.1我们采取的安全措施。</p>
    <p class="content">
      我们的网络服务采取了传输层安全协议等加密技术，确保用户数据在传输过程中的安全。
      通过采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。
      在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、加密脱敏等多种数据脱敏技术增强个人信息在使用中安全性。
    </p>
    <p class="content">
      设立严格的数据使用和访问制度，采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
    </p>
    <p class="content">采取专门的数据和技术安全审计，设立日志审计和行为审计多项措施</p>
    <p class="bold">3.2 保护个人信息采取的其他安全措施</p>
    <p class="content">
      通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
    </p>
    <p class="content">
      建立数据安全专项部门，负责安全应急响应组织来推进和保障个人信息安全。加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </p>
    <p class="bold">3.3 个人信息安全事件的通知</p>
    <p class="content">
      如发生个人信息引发的安全事件，我们将第一事件向相应主管机关报备，并即时进行问题排查，开展应急措施。
    </p>
    <p class="content">
      通过与全量用户发送通知提醒更改密码。还可能通过电话、短信等各种方式触达用户知晓，在我们公共运营平台运营宣传，制止数据泄露。
    </p>
    <p class="content">
      尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但我们仍然无法保证您的个人信息通过不安全途径进行交流时的安全性。因此，用户个人应采取积极措施保证个人信息的安全，如：定期修改帐号密码，不将自己的帐号密码等个人信息透露给他人。
    </p>
    <p class="content">
      您知悉：我们提供的个人信息保护措施仅适用于我们平台，一旦您离开我们，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在我们以外的网站提交的任何个人信息，无论您登录或浏览上述网站是否基于我们的链接或引导。
    </p>
    <p class="content">
      网络环境中始终存在各种信息泄漏的风险，当出现意外事件、不可抗力等情形导致您的信息出现泄漏时，我们将极力控制局面，及时告知您事件起因、我们采取的安全措施、您可以主动采取的安全措施等相关情况
    </p>
    <p class="bold">4、我们如何共享、转让、公开披露您的个人信息</p>
    <p class="bold">4.1 数据的存储与传输</p>
    <p class="content">
      我们会采取合适的安全措施和技术手段存储及保护您的个人信息，以防止丢失、被误用、受到未授权访问或泄漏、被篡改或毁坏。您的个人信息存放在有密码控制的位于中国境内的服务器中，访问均是受到限制的。当您需要跨境信息传输服务时，我们会默认您继续使用中国境内的服务器。
    </p>
    <p class="content">
      我们的业务可能需要我们转移您的个人数据至您所在地区（例如欧洲经济区）以外的国家，这些国家可能包括中国或新加坡。我们会采取适当的措施以确保您个人数据的接收者履行保密义务，并确保如标准合同条款等措施的执行。您可以通过联系我们的帮助中心获得这些条款。我们如涉及向境外传输个人信息，将会向用户明确告知并征得同意。
    </p>
    <p>4.2 数据的使用、共享与公开披露</p>
    <p class="content">
      为了优化我们的产品，给您提供更优质的服务，我们将与为本隐私政策规定之目的而为我们服务的第三方供应商或代理商分享个人数据。例如，我们聘请来提供数据分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。我们会要求所有分享数据的第三方按照本隐私政策规定保护您的数据隐私和安全。
    </p>
    <p class="content">
      在Android平台我们使用第三方统计分析服务“友盟+SDK”，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android
      ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI
      信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。
    </p>
    <p class="content">
      同时，我们还使用了“友盟+SDK”的推送服务，该推送服务将会集成国内各大 Android
      平台的推SKD，包括：
    </p>
    <p class="content">
      小米的“MiPush
      SDK”，你可以通过小米推送服务的隐私政策（https://dev.mi.com/console/doc/detail?pId=1822）进一步了解；
      魅族的“Flyme SDK
      ”，你可以通过魅族开放平台开发者协议（http://open-wiki.flyme.cn/doc-wiki/index#id?10）进一步了解；
      VIVO的“VIVO PUSH
      SDK”，你可以通过vivo消息推送平台服务协议（https://dev.vivo.com.cn/documentCenter/doc/366#w1-08512613）进一步了解；
      OPPO的“OPPO PUSH
      SDK”，你可以通过OPPO开发者服务协议（https://open.oppomobile.com/wiki/doc#id=10449）进一步了解；
      “Huawei Push
      Kit”，你可以通过华为推送服务使用协议（https://developer.huawei.com/consumer/cn/doc/app/20213）进一步了解。
      该等合作方及其服务由我们审慎地选择，且我们和合作方将共同运用各种安全技术和程序对数据进行加密处理，实施完善的机制来保护您的个人信息安全，以免遭受未经授权的访问、使用或披露。您可以通过合作方隐私政策(https://www.umeng.com/page/policy)
      进一步了解。
    </p>
    <p class="content">
      如果您不希望个人信息被友盟+处理，请通过合作方OPT-OUT渠道(https://outdip.umeng.com/opt_out.html)
      取消。
    </p>
    <p class="content">
      在iOS平台我们使用使用第三方统计分析服务“TalkingData应用统计分析SDK”，TalkingData应用统计分析SDK需要收集您的设备信息（品牌、型号、软件版本等）、网路信息（设备连接WiFi和基站信息）、位置信息（设备地理位置）、应用信息（SDK宿主应用包名、版本号等信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。同时，我们还使用了“友盟+SDK”的推送服务，用以向用户更好地提供内容推送服务。
    </p>
    <p class="content">
      该等合作方及其服务由我们审慎地选择，且我们和合作方将共同运用各种安全技术和程序对数据进行加密处理，实施完善的机制来保护您的个人信息安全，以免遭受未经授权的访问、使用或披露。您可以通过合作方隐私政策(http://www.talkingdata.com/privacy.jsp?languagetype=zh_cn)
      以及(https://www.umeng.com/page/policy) 进一步了解。
    </p>
    <p class="content">
      如果您不希望个人信息被TalkingData处理，请通过合作方OPT-OUT渠道(http://www.talkingdata.com/optout.jsp?languagetype=zh_cn)
      取消；如果您不希望个人信息被友盟+处理，请通过合作方OPT-OUT渠道(https://outdip.umeng.com/opt_out.html)
      取消。
    </p>
    <p class="content">
      如果您在本网站上向我们提供反馈，对于反馈中包含的您的个人信息，我们亦会按照本政策的约定进行保密，我们也将利用这些反馈信息来更好的提升我们的产品及服务。
    </p>
    <p class="content">
      我们可能通过包括邮件、电话或传真在内的其他资源获得您的个人信息。我们可能将这些信息与我们已通过本网络收集的您的信息合并在一起，从而改进我们提供的产品和服务。
    </p>
    <p class="content">
      根据本政策的规定，我们仅允许有必要知晓这些信息的我们员工等第三方访问个人信息，并要求他们履行相应的保密义务。
    </p>
    <p class="content">
      未经您本人允许，我们不会向任何第三方公开（包括共享、转让、公开披露等方式）您的个人信息，下列情形除外：
    </p>
    <p class="content">我们已经取得您或您监护人的授权或同意；</p>
    <p class="content">司法机关或行政机关基于法定程序要求我们披露的；</p>
    <p class="content">我们为维护自身合法权益而向用户提起诉讼或仲裁时；</p>
    <p class="content">根据您与我们务政策、应用许可使用协议的约定；</p>
    <p class="content">在法律允许的范围内，为保障我们及社会公共利益免受损害时；</p>
    <p class="content">符合您与其他第三人之间有关约定的；/p></p>
    <p class="content">
      当您因为您的共享行为导致您的信息发生泄漏等严重情况时，我们不承担相关法律责任。
    </p>
    <p class="bold">4.3 数据的转让</p>
    <p class="content">
      随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。
    </p>
    <p class="bold">5. 数据处理的法律基础</p>
    <p class="content">
      在某些情况下，您可能需要为我们提供个人数据以进行上述的处理，以便我们能够为您提供我们的全部服务，并方便您使用我们网站的全部功能。
    </p>
    <p class="bold">6. 您如何管理您的个人信息</p>
    <p class="content">
      根据适用法律，您有权反对或要求限制对您个人数据的处理，并要求您本人数据的访问、校正、删除和可携带权。
    </p>
    <p class="content">
      在对您的信息的使用是基于您的同意的情况下，您有权在任何时候撤回该同意，但同意的撤回不影响撤回前基于同意进行的数据处理的合法性。您可以通过
      service@nilwy.com 向我们提交您的要求。
    </p>
    <p class="content">
      如果您发现您的信息发生变化或不准确，请将这些变化通知我们以便我们的记录可以被更新或更正。如果您认为我们对您个人数据的处理违反了适用法律，您可以向监管机构投诉。
      我们对您个人数据的保留时间视向您提供服务或产品的需要而定，或根据适用法律如税法和会计法的要求或许可确定。
    </p>
    <p class="content">
      您可以自主要求（应用设置 - 账号与安全 - 更多-
      注销账号）注销账号。在您注销您的账号之前，我们将验证您的个人身份、安全状态、常用设备等信息。您注销账号的行为是不可逆的行为，一旦您注销您的账号，我们将不再收集您的个人信息，并即刻删除有关您账户的一切信息，并保证这些信息不会泄露，同时，您也无法通过已注销的账户获得我们产品的服务。
    </p>
    <p class="content">
      当您完成我们注册并进行合理和必要的身份验证后，您可以查阅、修改、删除您提交给我们的个人信息。一般情况下，您可随时浏览、修改、删除自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的某些初始注册信息及验证信息。
    </p>
    <p class="content">
      您可以通过 service@nilwy.com
      向我们提交数据导出需求。在导出数据之前，我们将验证您的个人身份、安全状态等信息。
    </p>
    <p class="content">
      我们始终将对您个人信息的保护放在首位，同时，我们重视您对个人信息的关注。对于您的个人信息，您可以通过手机客户端等途径在线查询、修改、删除您的个人信息。
    </p>
    <p class="content">
      您有权自主更新或更正您的个人信息，或授权我们行信息更新、更正。在您进行信息更新更正之前，我们会首先验证您的身份，其次才能进行信息的更正与更新。
    </p>
    <p class="content">
      如果您对本隐私政策或其中有关您个人信息的收集、使用、存储、保护等功能存在意见或建议时，您可以通过我们邮件渠道反馈意见或投诉，我们会在收到您意见及建议的第一时间将反馈信息回复给您。
    </p>
    <p class="content">
      您可以通过删除我们 app
      的方式阻止我们获得您的个人信息，关闭部分功能之后，我们将无法向您提供相应的服务。
    </p>
    <p class="content">
      如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
    </p>
    <p class="bold">7. 对未成年人个人信息的特别保护</p>
    <p class="content">
      我们非常重视对未成年人个人信息的保护。若您是 14
      周岁以下的未成年人，在使用我们的服务前，应确保事先取得监护人的同意，如果您是已满 14
      不满 18
      周岁的未成年人，在使用我们服务前，应确保事先取得本人或监护人的同意。如您在我们注册帐号，我们将默认为您已得到前述同意。我们将根据国家相关法律法规及本《隐私政策》的规定保护未成年人的个人信息。
    </p>
    <p class="bold">8. 隐私政策的修改</p>
    <p class="content">
      为给您提供更好的服务以及随着我们业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据当前生效隐私政策所应享有的权利。
    </p>
    <p class="content">
      我们会通过在网站、移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，以便及时了解最新的隐私政策。
    </p>
    <p class="content">
      对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信、私信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
    </p>
    <p class="content">本政策所指的重大变更包括但不限于：</p>
    <p class="content">
      我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p>
    <p class="content">
      我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p class="content">个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p class="content">您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p class="content">
      我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p class="content">个人信息安全影响评估报告表明存在高风险时。</p>
    <p class="content">
      除非本隐私政策中另有说明，经过您的允许或在以下情况下，否则我们不会与第三方分享您的个人信息：
    </p>
    <p class="content">
      服务要求披露。我们会向帮助提供服务的有关人员披露个人信息，包括执行技术、管理和数据处理任务（如托管、计费、实施、数据存储和安全）的人员。
      法律要求或为了保护权利。如果法律要求，或者为了响应传票或法院指令，或者当我们单方面有合理的理由认为为了保护口袋时间、第三方或一般大众的财产或权利必须进行披露时，我们将披露个人信息。
    </p>
    <p class="content">
      业务转让、破产。如果进行合并、收购或破产或者销售我们的部分或全部资产，我们有权将拥有的所有个人信息转让给接任的组织。除了破产或其它法院指定的范围，使用和披露所有转让的个人信息将受本隐私政策的约束，如果您被通知将出台新的隐私政策，本隐私政策有可能停止，则使用和披露行为受新隐私政策的约束。但是，转让后提交或收集的个人信息须遵守我们的接任组织制定的新隐私政策或政策。
    </p>
    <p class="content">
      您在本站内发布信息时，应严格遵守相关规定，如果我们发现您发布法律、法规禁止发布或者传输的信息，我们有权立即停止传输该信息，采取消除等处置措施，保存有关记录，并向有关主管部门报告。
    </p>
    <p class="content">
      如果有其他客户发现您发布的信息泄露个人身份、散布个人隐私等侵害其合法权益，或您如在我们许可的情况下发布商业性电子信息，而其他客户表示遭受侵扰，这些客户有权要求我们删除您发布的信息，或者采取其他必要措施予以制止。我们将会依法采取这些措施。
    </p>
    <p class="bold">9. 争议解决</p>
    <p class="content">
      当您因为本政策的实施与我们产生任何纠纷时，双方应首先协商友好解决；若不能协商解决，双方有权依照注册协议向有管辖权的法院提起诉讼。
    </p>
    <p class="content">
      如因我们使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，我们不承担任何责任。我们不对第三方软件或技术提供客服支持，若您需要获取支持，请与第三方联系。针对您使用本站、app
      适用的政策和条件包括在单独的用户协议中，请访问（“用户协议”）查看。
    </p>
    <p class="content">
      如果您仍对本隐私政策有任何疑问，请您联系我们（service@nilwy.com），我们会及时解决您的问题。
    </p>
    <p class="bold">8. 第三方SDK目录</p>
    <p>
      1、极光认证（所属机构极光），极光认证SDK，功能：用于实现App一键登录，号码认证的功能，收集个人信息的类型：设备信息、位置信息、网络信息、SDK认证相关请求结果、手机号码。极光隐私政策链接：https://www.jiguang.cn/license/privacy，设备信息：设备标识符（IMEI、IDFA、Android
      ID、IMSI、MAC、OAID相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）
      网络信息：IP地址，WiFi信息，基站信息。官网链接：https://docs.jiguang.cn/jverification/guideline/intro/
    </p>
    <p>
      2、友盟统计（所属机构友盟），用于app闪退监控服务，收集个人常用设备信息、闪退信息，官网链接：https://developer.umeng.com/docs/119267/cate/119432
    </p>
    <p>
      3、WechatOpenSDK（所属机构腾讯），用于提供用户登录、分
      享、支付服务，收集个人常用设备信息，官网链接：https://developers.weixin.qq.com/doc/oplatform/Mobile_App/Access_Guide/Android.html
    </p>
    <p class="bold">9. 设备信息</p>
    <p class="bold">1）图片获取、存储与上传功能</p>
    <p class="content">
      （1）当您使用我们APP产品/服务时，为实现需要拍照上传至应用内上传头像的功能，我们需要获取您设备的“相机-拍摄”权限；
      （2）为实现上传图片、上传头像的功能，我们需要获取您设备的“存储-读取外置存储器”权限；
      （3）为实现保存图片的功能，我们需要获取您设备的“写入外置存储器”权限。
    </p>
    <p class="bold">厦门逆为网络科技有限公司</p>
  </div>
</template>

<script>
export default {
  name: "privacy",
};
</script>

<style lang="less" scoped>
.privacy {
  padding: 15px;
  .right {
    text-align: right;
  }
  .title {
    font-size: 18px;
    color: #333;
    text-align: center;
    line-height: 36px;
    padding: 10px;
  }
  .content {
    text-indent: 25px;
  }
  .content em {
    font-style: normal;
    text-decoration: underline;
  }
  .content b {
    color: #333;
    text-decoration: underline;
  }
  .bold {
    font-weight: bold;
  }
  p {
    font-size: 14px;
    color: #333;
    line-height: 23px;
    margin-bottom: 7px;
    word-break: break-all;
  }
}
</style>
